import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IonMenu,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSearchbar,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { warning } from "ionicons/icons";
import Modal from "react-bootstrap/Modal";
import ProjectStatus from "../components/ProjectStatus";
import alerts from "../data/alerts";
import "./Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const [searchText, setSearchText] = useState("");

  const [AlertModalShow, setAlertModalShow] = React.useState(false);
  const [AlertModalTitle, setAlertModalTitle] = React.useState();
  const [AlertModalProject, setAlertModalProject] = React.useState();
  const [AlertModalDescription, setAlertModalDescription] = React.useState();

  function setModalState(alert) {
    setAlertModalShow(true);
    setAlertModalTitle(alert.title);
    setAlertModalProject(alert.projectName);
    setAlertModalDescription(alert.longDescription);
  }

  function AlertModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        dialogClassName="modal-component"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {AlertModalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectStatus bgColor="#ff6f00">{AlertModalProject}</ProjectStatus>
          <p className="ion-margin-top">{AlertModalDescription}</p>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <IonMenu side="end" className="menu-right" id="sidebar">
      <IonContent className="no-background">
        {/* <IonSearchbar
          style={{ marginTop: "5px" }}
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value)}
        ></IonSearchbar> */}
        {(location.pathname === "/home" ||
          location.pathname === "/projects" ||
          location.pathname === "/projects/coquina-beach-improvements" ||
          location.pathname === "/projects/force-main-1" ||
          location.pathname === "/projects/ami-watermain-improvements" ||
          location.pathname === "/projects/force-main-8" ||
          location.pathname === "/projects/force-main-15" ||
          location.pathname ===
            "/projects/holmes-boulevard-force-main-replacement" ||
          location.pathname ===
            "/projects/bradenton-beach-sewer-rehabilitation") && (
          <div>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Latest alerts</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {alerts.map((alert) => (
                  <IonItem
                    lines="none"
                    button
                    onClick={() => setModalState(alert)}
                    key={alert.id}
                  >
                    <IonIcon
                      ios={warning}
                      md={warning}
                      slot="start"
                      size="large"
                      color="warning"
                      className="alert-icon"
                    />
                    <IonLabel className="ion-text-wrap">
                      <h2>{alert.title}</h2>
                      <p>{alert.description}</p>
                    </IonLabel>
                  </IonItem>
                ))}
                <AlertModal
                  show={AlertModalShow}
                  onHide={() => setAlertModalShow(false)}
                />
              </IonCardContent>
            </IonCard>
          </div>
        )}
        {location.pathname === "/alerts" && (
          <div>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  Have concerns regarding other Manatee County maintenance or
                  construction activities?
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  fill="solid"
                  className="button-text-white"
                  shape=""
                  size="default"
                  href="https://www.mymanatee.org/departments/public_works/report_an_issue"
                  target="_blank"
                >
                  Report Them Here
                </IonButton>
              </IonCardContent>
            </IonCard>
          </div>
        )}
        {location.pathname === "/resources" && (
          <div>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Have Questions?</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  fill="solid"
                  size="dedault"
                  routerLink="/contact"
                  routerDirection="none"
                  className="button-text-white"
                >
                  Contact A Representative
                </IonButton>
              </IonCardContent>
            </IonCard>
          </div>
        )}
        {location.pathname === "/contact" && (
          <div>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Additional Resources</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton
                  fill="solid"
                  size="dedault"
                  target="_blank"
                  href="https://mymanatee.org/gisportal/apps/opsdashboard/index.html#/475ee26e749047c29181d5f5f597c6dd"
                  className="button-text-white"
                >
                  Capital Project Dashboard
                </IonButton>
                <br />
                <IonButton
                  fill="solid"
                  size="dedault"
                  target="_blank"
                  href="https://www.mymanatee.org/"
                  className="button-text-white"
                >
                  MyManatee.org
                </IonButton>
              </IonCardContent>
            </IonCard>
          </div>
        )}
      </IonContent>
    </IonMenu>
  );
};

export default Sidebar;
