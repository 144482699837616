import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonTextarea,
  IonText,
} from "@ionic/react";
import {
  informationCircleOutline,
  notificationsOutline,
  documentTextOutline,
  callOutline,
  homeOutline,
} from "ionicons/icons";
import "./Menu.css";
//import PrivacyModal from "../components/PrivacyModal";
import Modal from "react-bootstrap/Modal";

function PrivacyModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="modal-component"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Thank you for visiting the Anna Maria Island Improvement Projects
          website and reviewing our privacy policy.
        </p>
        <h2>
          <strong>Electronic Mail (E-mail)</strong>
        </h2>
        <p>
          Under Florida law, e-mail addresses are public records. If you do not
          want your e-mail address released in response to a public-records
          request, please do not send e-mail to this entity. Instead, contact us
          by phone or in writing.
        </p>
        <h2>
          <strong>Information Collected and Stored Automatically</strong>
        </h2>
        <p>
          Anna Maria Island Improvement Projects will collect no personal
          information about you when you visit our website unless you choose to
          provide that information to us. However, as you browse through the
          website, read pages or download information, we will gather and store
          certain information about your visit automatically. This information
          does not identify you personally. We automatically collect and store
          only the following information about your visit when you access our
          website:
          <ul>
            <li>
              The internet domain (for example, "xcompany.com") and IP address
              (an IP address is a number that is automatically assigned to your
              computer whenever you are on the internet);
            </li>
            <li>
              The type of browser and operating system used to access our
              website;
            </li>
            <li>The date and time you access our website;</li>
            <li>The pages you visit; and</li>
            <li>
              If you linked to the Anna Maria Island Improvement Projects
              website from another website, the IP address of that website.
            </li>
          </ul>
          We use this information to help us make our website more useful to
          visitors, to learn about the number of visitors to our site and the
          types of technology our visitors use. We do not track or record
          information about individuals and their visits.
        </p>
        <h2>
          <strong>If You Send Us Personal Information</strong>
        </h2>
        <p>
          If you choose to provide us with personal information - as in an
          e-mail to Anna Maria Island Improvement Projects staff or by
          completing a form with your personal information and submitting it to
          us through our website - we use that information to respond to your
          message and provide you the information you have requested. We treat
          e-mails the same way we treat letters sent to the Anna Maria Island
          Improvement Projects offices. We are required to maintain many
          documents for historical purposes, but we do not collect personal
          information for any purpose other than to respond to you. However,
          information we receive may be considered public information which is
          subject to disclosure under Florida law. Except for information
          disclosure required by Florida law, any personal information you
          provide would only be shared with another government agency, if your
          inquiry relates to that agency. Moreover, we do not create individual
          profiles with the information you provide or provide such information
          to any private organizations. Anna Maria Island Improvement Projects
          does not collect information for commercial marketing.
        </p>
        <h2>
          <strong>Protection of Minors</strong>
        </h2>
        <p>
          It is important that children receive adult guidance prior to
          providing personal information online. We specifically ask that
          children to get the permission of a responsible adult or guardian
          before providing any information online - at our site or any other
          site - and recommend a responsible adult always be involved in online
          activities and actions. Should a child receive permission to provide
          personal information through the Anna Maria Island Improvement
          Projects website, that information will only be used to enable us to
          respond to the writer. We will also automatically collect the
          information as described above in Information Collected and Stored
          Automatically.
        </p>
        <strong>Information Collected and Stored Automatically.</strong>
        <h2>
          <strong>Cookie Use</strong>
        </h2>
        <p>
          A cookie is a tiny piece of text that, with your permission, is placed
          on your computer hard drive. If your browser is set to notify you
          before accepting a cookie, you will receive a request for a cookie to
          be stored on your computer. This cookie, by itself, will only advise
          us that a previous visitor has returned. It does not provide private
          information about you. The acceptance of a cookie is optional and will
          not prevent or preclude you from accessing information on the 44th Ave
          East Extension Projects website.
        </p>
      </Modal.Body>
    </Modal>
  );
}

function AccessibilityModal(props) {
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [EmailAddress, setEmailAddress] = useState();
  const [Message, setMessage] = useState();
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="modal-component"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Web Accessibility
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The developers of this website strive to ensure that its services are
          accessible to people with disabilities, with the strong belief that
          every person has the right to live with dignity, equality, comfort and
          independence both in life, and on the internet.
        </p>
        <p>
          If you are unable to use any aspect of this website, PDF files, or
          other attachments, we can provide you with prompt assistance. Please
          let us know by filling out the form below or by emailing{" "}
          <a href="mailto:ada-support@valerin-group.com">
            ada-support@valerin-group.com
          </a>
        </p>
        <IonRow>
          <IonCol>
            <h4 className="ion-margin-bottom ion-margin-top">
              Report an issue
            </h4>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">First Name</IonLabel>
              <IonInput
                value={FirstName}
                onIonChange={(e) => setFirstName(e.detail.value)}
              ></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Last Name</IonLabel>
              <IonInput
                value={LastName}
                onIonChange={(e) => setLastName(e.detail.value)}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Phone Number</IonLabel>
              <IonInput
                value={PhoneNumber}
                onIonChange={(e) => setPhoneNumber(e.detail.value)}
              ></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Email Address</IonLabel>
              <IonInput
                value={EmailAddress}
                onIonChange={(e) => setEmailAddress(e.detail.value)}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Message</IonLabel>
              <IonTextarea
                value={Message}
                onIonChange={(e) => setMessage(e.detail.value)}
              ></IonTextarea>
            </IonItem>
            <IonButton
              fill="solid"
              size="dedault"
              className="ion-margin-top ion-margin-bottom button-text-white"
            >
              Send Message
            </IonButton>
            <br />
            <IonText color="medium">
              <p>
                Under Florida law, e-mail addresses are public records. If you
                do not want your e-mail address released in response to a
                public-records request, do not send electronic mail to this
                entity. Instead, contact this office by phone or in writing.
              </p>
            </IonText>
          </IonCol>
        </IonRow>
      </Modal.Body>
    </Modal>
  );
}

const Menu = () => {
  const location = useLocation();
  const [PrivacyModalShow, setPrivacyModalShow] = React.useState(false);
  const [AccessibilityModalShow, setAccessibilityModalShow] = React.useState(
    false
  );
  return (
    <IonMenu
      contentId="main"
      type="overlay"
      className="menu-left"
      swipeGesture={false}
    >
      <IonContent className="no-background">
        <IonList id="menu-list">
          <div className="logo-img">
            <img src="/assets/AMI-Icon-Blue.png" alt="AMI Logo" />
          </div>

          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/home" ? "selected" : ""}
              routerLink="/home"
              routerDirection="none"
              lines="none"
              detail={location.pathname === "/home" ? true : false}
            >
              <IonIcon slot="start" ios={homeOutline} md={homeOutline} />
              <IonLabel>Home</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              className={
                location.pathname === "/projects" ||
                location.pathname === "/projects/coquina-beach-improvements" ||
                location.pathname === "/projects/force-main-1" ||
                location.pathname === "/projects/ami-watermain-improvements" ||
                location.pathname === "/projects/force-main-8" ||
                location.pathname === "/projects/force-main-15" ||
                location.pathname ===
                  "/projects/holmes-boulevard-force-main-replacement" ||
                location.pathname ===
                  "/projects/bradenton-beach-sewer-rehabilitation"
                  ? "selected"
                  : ""
              }
              routerLink="/projects"
              routerDirection="none"
              lines="none"
              detail={location.pathname === "/projects" ? true : false}
            >
              <IonIcon
                slot="start"
                ios={informationCircleOutline}
                md={informationCircleOutline}
              />
              <IonLabel>Projects</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/alerts" ? "selected" : ""}
              routerLink="/alerts"
              routerDirection="none"
              lines="none"
              detail={location.pathname === "/alerts" ? true : false}
            >
              <IonIcon
                slot="start"
                ios={notificationsOutline}
                md={notificationsOutline}
              />
              <IonLabel>Alerts</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/resources" ? "selected" : ""}
              routerLink="/resources"
              routerDirection="none"
              lines="none"
              detail={location.pathname === "/resources" ? true : false}
            >
              <IonIcon
                slot="start"
                ios={documentTextOutline}
                md={documentTextOutline}
              />
              <IonLabel>Resources</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/contact" ? "selected" : ""}
              routerLink="/contact"
              routerDirection="none"
              lines="none"
              detail={location.pathname === "/contact" ? true : false}
            >
              <IonIcon slot="start" ios={callOutline} md={callOutline} />
              <IonLabel>Contact</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        <IonGrid className="footer-link-grid">
          <IonRow className="ion-justify-content-center">
            <IonCol>
              <div>
                <IonButton
                  fill="clear"
                  size="dedault"
                  onClick={() => setAccessibilityModalShow(true)}
                >
                  Accessibility
                </IonButton>
                <AccessibilityModal
                  show={AccessibilityModalShow}
                  onHide={() => setAccessibilityModalShow(false)}
                />
              </div>
            </IonCol>
            <IonCol>
              <div style={{ textAlign: "start" }}>
                <IonButton
                  fill="clear"
                  size="dedault"
                  onClick={() => setPrivacyModalShow(true)}
                >
                  Privacy
                </IonButton>
                <PrivacyModal
                  show={PrivacyModalShow}
                  onHide={() => setPrivacyModalShow(false)}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div style={{ textAlign: "center" }}>
                &#169; Copyright 2020&nbsp;
                <a
                  href="https://mymanatee.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Manatee County
                </a>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
