/* eslint-disable */
const projects = require('./projects.json');

[projects].flat().forEach((project) => {

	if (project.projectMap)
		project.projectMap = [
			{
				original: `/assets/${project.id}.jpg`,
				thumbnail: `/assets/${project.id}.jpg`,
			  },
		]
	
	
});
export { projects }; 