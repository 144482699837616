import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Helmet } from "react-helmet";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

export const FAQ_DATA = [
  {
    id: "1",
    question: "What can I expect to see during construction?",
    answer:
      "Crews will be locating utilities and clearing the right of way before starting directional drilling or excavation for pipe installation in the project corridor. Where trenches are excavated, pumps may be used to remove the groundwater. Residents and visitors are asked to remain alert and exercise caution around construction equipment and materials in work zones and nearby areas.",
  },
  {
    id: "2",
    question:
      "When will my area be affected by construction activities and how will I be notified? ",
    answer:
      "These projects are divided into several phases. You may see intermittent work as well as different types of construction activities happening at the same time. In addition to information provided on the project website, door hangers or flyers will be distributed in advance of major construction activities starting in your area.  These notices will let residents know about upcoming activities, potential impacts, and any special instructions that may apply. Construction activities may be rescheduled for the following day(s) without additional notice.",
  },
  {
    id: "3",
    question: "How will my property be affected by construction activities? ",
    answer:
      "You may see crews start clearing right-of-way and dewatering – eliminating excess water – ahead of the pipeline installation.  The right-of-way extends approximately 15 feet from the edge of pavement and landscape and hardscape items within this area, including trees and driveways, will be removed.",
  },
  {
    id: "4",
    question: "Will pipes be laid on my property? ",
    answer:
      "Construction activities will take place within Manatee County and City right-of-way.",
  },
];

const Resources = () => {
  return (
    <div>
      <Helmet>
        <title>AMI - Resources</title>
      </Helmet>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Resources</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Resources</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className="content-wrap">
            <h4 className="ion-margin-bottom">Frequently Asked Questions</h4>
            <Accordion>
              {FAQ_DATA.map((faq) => (
                <Card key={faq.id}>
                  <Accordion.Toggle as={Card.Header} eventKey={faq.id}>
                    {faq.question}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={faq.id}>
                    <Card.Body>{faq.answer}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </IonContent>
      </IonPage>
    </div>
  );
};

export default Resources;
