import styled from "styled-components";

const ProjectStatus = styled.span`
  /* Adapt the colors based on primary prop */
  background: ${(props) => props.bgColor || "white"};
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #ffffff;
  // position: absolute;
  // right: 25px;
`;

export default ProjectStatus;
