import styled from "styled-components";

const ProjectTitle = styled.h1`
  /* Adapt the colors based on primary prop */
  font-size: 2.4rem;
  font-weight: 600;
  color: #000;

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

export default ProjectTitle;
