import React from "react";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

import Menu from "./components/Menu.jsx";
import Sidebar from "./components/Sidebar.jsx";
import Home from "./pages/Home.jsx";
import Projects from "./pages/Projects.jsx";
import ProjectDetails from "./pages/ProjectDetails.jsx";
import Alerts from "./pages/Alerts.jsx";
import Resources from "./pages/Resources.jsx";
import Contact from "./pages/Contact.jsx";

import { Helmet } from "react-helmet";

import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./theme/variables.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-gallery/styles/css/image-gallery.css";

const App = () => {
  return (
    <div>
      <Helmet>
        <title>AMI - Home</title>
      </Helmet>
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/home" component={Home} />
              <Route path="/projects" component={Projects} exact />
              <Route path="/projects/:id" component={ProjectDetails} />
              <Route path="/alerts" component={Alerts} />
              <Route path="/resources" component={Resources} />
              <Route path="/contact" component={Contact} />
              <Redirect from="/" to="/home" exact />
            </IonRouterOutlet>
            <Sidebar />
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </div>
  );
};

export default App;
