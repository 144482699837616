import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { Helmet } from "react-helmet";
import Jumbotron from "react-bootstrap/Jumbotron";
import ImageGallery from "react-image-gallery";

// const images = [
//   {
//     original: "/assets/IMG_1378.jpg",
//     thumbnail: "/assets/IMG_1378.jpg",
//   },
//   {
//     original: "/assets/IMG_1380.jpg",
//     thumbnail: "/assets/IMG_1380.jpg",
//   },
//   {
//     original: "/assets/IMG_1384.jpg",
//     thumbnail: "/assets/IMG_1384.jpg",
//   },
//   {
//     original: "/assets/IMG_1390.jpg",
//     thumbnail: "/assets/IMG_1390.jpg",
//   },
//   {
//     original: "/assets/IMG_1393.jpg",
//     thumbnail: "/assets/IMG_1393.jpg",
//   },
//   {
//     original: "/assets/IMG_1396.jpg",
//     thumbnail: "/assets/IMG_1396.jpg",
//   },
// ];

const images = [
  {
    original: "/assets/ami-map-full.jpg",
    thumbnail: "/assets/ami-map-full.jpg",
  },
];

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>AMI - Home</title>
      </Helmet>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle size="large">Home</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="background">
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Home</IonTitle>
            </IonToolbar>
          </IonHeader>
          <Jumbotron>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h2 className="ion-no-margin">
                    Anna Maria Island Improvement Projects
                  </h2>
                </IonCol>
              </IonRow>
            </IonGrid>
          </Jumbotron>
          <div className="content-wrap">
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <div className="project-body">
                    <div className="project-img">
                      <ImageGallery
                        items={images}
                        showPlayButton={false}
                        showThumbnails={false}
                      />
                    </div>
                    <div className="project-text">
                      <p className="ion-no-margin">
                        As part of the Capital Improvement Plan (CIP), Manatee
                        County is replacing aging drinking water and wastewater
                        pipelines on Anna Maria Island. The County is also
                        constructing stormwater drainage improvements for the
                        Coquina Beach public parking. Many of the existing
                        pipelines included in these projects were constructed in
                        the 1970’s and are now reaching the end of their service
                        life.
                      </p>
                      <p>
                        The pipelines being replaced on the island include force
                        mains, water mains and drainage pipe. Force mains are
                        pressurized pipelines that convey raw wastewater to the
                        treatment facility. Water mains are pressurized
                        pipelines that supply drinking water to homes and
                        businesses. Drainage pipes collect stormwater from
                        rainfall events and use gravity to transfer the water to
                        ponds for storage and treatment. All these water systems
                        are vital to the quality of life enjoyed by residents,
                        businesses and visitors to Anna Maria Island.
                      </p>
                      <p>
                        The pipelines will be constructed using a mix of
                        open-cut excavation and directional drilling. The
                        construction will generally take place between 7 A.M. to
                        7 P.M. and will require periodic lane closures, detours
                        and flagging operations as needed to install the
                        pipelines. Access to homes and businesses will be
                        maintained and any planned interruptions in water
                        service will be noticed in advance and are expected to
                        be relatively short in nature.
                      </p>
                      <p>
                        All construction activities will take place within the
                        Manatee County, City of Anna Maria and City Bradenton
                        Beach established property easements and right-of-way.
                        All projects will include restoration of roadway and
                        sidewalk pavement disturbed by construction of the force
                        mains and force gravity sewer. Additionally, the
                        contractor will replace all concrete, brick, and shell
                        driveways, landscaping, grass, and any mailboxes and
                        signs that may need to be removed as part of the
                        pipeline construction projects.
                      </p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonPage>
    </div>
  );
};

export default Home;
