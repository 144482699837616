import React from "react";
import { useParams } from "react-router-dom";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonRow,
  IonGrid,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { Helmet } from "react-helmet";
import Jumbotron from "react-bootstrap/Jumbotron";
import ProjectTitle from "../components/ProjectTitle";
import ProjectStatus from "../components/ProjectStatus";
import ProjectLocation from "../components/ProjectLocation";
import ImageGallery from "react-image-gallery";
import { projects } from "../data/projects";
import { pinSharp } from "ionicons/icons";

const ProjectDetails = () => {
  const params = useParams();
  const selectedProject = projects.find((p) => p.id === params.id);

  function createProjectDescription() {
    return {
      __html: (selectedProject.description || "").replace(/\n/g, "<br>"),
    };
  }
  function createProjectCurrentActivities() {
    return {
      __html: (selectedProject.currentActivities || "").replace(/\n/g, "<br>"),
    };
  }
  return (
    <div>
      <Helmet>
        <title>AMI - {selectedProject.name}</title>
      </Helmet>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/projects" />
            </IonButtons>
            <IonTitle>Project Details</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large"></IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <Jumbotron className="project-detail-header">
              <IonGrid>
                <IonRow class="ion-align-items-center">
                  <IonCol sizeMd="8" sizeXs="12">
                    <ProjectTitle>{selectedProject.name}</ProjectTitle>
                    <span style={{ fontWeight: 500 }}>
                      <IonIcon
                        icon={pinSharp}
                        color="primary"
                        // size="large"
                        style={{ marginBottom: "-5px", fontSize: "30px" }}
                      />{" "}
                      <ProjectLocation>
                        {selectedProject.location}
                      </ProjectLocation>
                    </span>
                  </IonCol>
                  <IonCol sizeMd="4" sizeXs="12">
                    <div className="project-detail-right-top">
                      <ProjectStatus bgColor={selectedProject.statusColor}>
                        {selectedProject.status}
                      </ProjectStatus>
                      <br />
                      <div className="project-dates">
                        <span style={{ fontWeight: 500 }}>Start:</span>{" "}
                        <ProjectLocation>
                          {selectedProject.startDate}
                        </ProjectLocation>
                        <br />
                        <span style={{ fontWeight: 500 }}>Finish:</span>{" "}
                        <ProjectLocation>
                          {selectedProject.endDate}
                        </ProjectLocation>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </Jumbotron>

            <div className="content-wrap">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <div className="project-body">
                      <div className="project-img">
                        <ImageGallery
                          items={selectedProject.projectMap}
                          showPlayButton={false}
                          showThumbnails={false}
                        />
                      </div>
                      <div className="project-text">
                        {selectedProject.description && (
                          <div
                            className="ion-margin-bottom"
                            dangerouslySetInnerHTML={createProjectDescription()}
                          />
                        )}

                        {selectedProject.currentActivities && (
                          <div>
                            <h4 className="ion-margin-bottom">
                              Current Activites
                            </h4>
                            <div
                              className="ion-margin-bottom"
                              dangerouslySetInnerHTML={createProjectCurrentActivities()}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>
        </IonContent>
      </IonPage>
    </div>
  );
};

export default ProjectDetails;
