import styled from "styled-components";

const ProjectLocation = styled.span`
  /* Adapt the colors based on primary prop */
  font-size: 1.2rem;
  font-weight: 500;
  color: #000000;
  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

export default ProjectLocation;
