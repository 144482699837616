import React from "react";
import ReactDOM from "react-dom";
import {
    setupConfig
  } from "@ionic/react";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";

setupConfig({
    mode: 'md',
    statusTap: true,
    swipeBackEnabled: false
  });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
