import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonIcon,
  IonLabel,
  IonList,
  IonButton,
} from "@ionic/react";
import { warning } from "ionicons/icons";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import ProjectStatus from "../components/ProjectStatus";
import alerts from "../data/alerts";

const Alerts = () => {
  const [AlertModalShow, setAlertModalShow] = React.useState(false);
  const [AlertModalTitle, setAlertModalTitle] = React.useState();
  const [AlertModalProject, setAlertModalProject] = React.useState();
  const [AlertModalDescription, setAlertModalDescription] = React.useState();

  function setModalState(alert) {
    setAlertModalShow(true);
    setAlertModalTitle(alert.title);
    setAlertModalProject(alert.projectName);
    setAlertModalDescription(alert.longDescription);
  }

  function AlertModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        dialogClassName="modal-component"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {AlertModalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectStatus bgColor="#ff6f00">{AlertModalProject}</ProjectStatus>
          <p className="ion-margin-top">{AlertModalDescription}</p>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <div>
      <IonPage id="alertsPage">
        <Helmet>
          <title>AMI - Alerts</title>
        </Helmet>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Alerts</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Alerts</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className="content-wrap">
            {alerts.map((alert) => (
              <IonItem
                lines="none"
                button
                onClick={() => setModalState(alert)}
                key={alert.id}
              >
                <IonIcon
                  ios={warning}
                  md={warning}
                  slot="start"
                  size="large"
                  color="warning"
                  className="alert-icon"
                />
                <IonLabel className="ion-text-wrap">
                  <h2>{alert.title}</h2>
                  <p>{alert.description}</p>
                </IonLabel>
              </IonItem>
            ))}
            <AlertModal
              show={AlertModalShow}
              onHide={() => setAlertModalShow(false)}
            />
          </div>
        </IonContent>
      </IonPage>
    </div>
  );
};

export default Alerts;
