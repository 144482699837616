import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
} from "@ionic/react";
import { mapOutline } from "ionicons/icons";
import { Helmet } from "react-helmet";
import ProjectStatus from "../components/ProjectStatus";
import { InteractiveMap } from "../components/InteractiveMap";

const Projects = () => {
  return (
    <div>
      <Helmet>
        <title>AMI - Projects</title>
      </Helmet>
      <IonPage id="Projects">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            {/* <IonButtons slot="end">
            <IonButton color="light">
              <IonIcon slot="icon-only" icon={mapOutline} />
            </IonButton>
          </IonButtons> */}
            <IonTitle>Projects</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Projects</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className="content-wrap">
            <IonGrid>
              <IonRow>
                <IonCol sizeMd="6" sizeXs="12">
                  <ProjectStatus bgColor="#ff6f00">
                    In Construction
                  </ProjectStatus>
                  <IonList className="project-list ion-margin-bottom">
                    <IonItem
                      routerLink={`/projects/coquina-beach-improvements`}
                      routerDirection="forward"
                      lines="none"
                    >
                      <IonLabel className="ion-text-wrap">
                        Coquina Beach Improvements
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      routerLink={`/projects/force-main-1`}
                      routerDirection="forward"
                      lines="none"
                    >
                      <IonLabel className="ion-text-wrap">
                        Force Main 1
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      routerLink={`/projects/ami-watermain-improvements`}
                      routerDirection="forward"
                      lines="none"
                    >
                      <IonLabel className="ion-text-wrap">
                        AMI Watermain Improvements
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      routerLink={`/projects/force-main-8`}
                      routerDirection="forward"
                      lines="none"
                    >
                      <IonLabel className="ion-text-wrap">
                        Force Main 8
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      routerLink={`/projects/force-main-15`}
                      routerDirection="forward"
                      lines="none"
                    >
                      <IonLabel className="ion-text-wrap">
                        Force Main 15
                      </IonLabel>
                    </IonItem>
                  </IonList>
                </IonCol>

                <IonCol sizeMd="6" sizeXs="12">
                  <ProjectStatus bgColor="#1051b3">In Design</ProjectStatus>
                  <IonList className="project-list">
                    <IonItem
                      routerLink={`/projects/holmes-boulevard-force-main-replacement`}
                      routerDirection="forward"
                      lines="none"
                    >
                      <IonLabel className="ion-text-wrap">
                        Holmes Boulevard Force Main Replacement
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      routerLink={`/projects/bradenton-beach-sewer-rehabilitation`}
                      routerDirection="forward"
                      lines="none"
                    >
                      <IonLabel className="ion-text-wrap">
                        Bradenton Beach Sewer Rehabilitation
                      </IonLabel>
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          {/* <InteractiveMap /> */}
        </IonContent>
      </IonPage>
    </div>
  );
};

export default Projects;
