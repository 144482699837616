import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonItem,
  IonInput,
  IonLabel,
  IonTextarea,
  IonText,
} from "@ionic/react";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [EmailAddress, setEmailAddress] = useState();
  const [Message, setMessage] = useState();

  return (
    <div>
      <Helmet>
        <title>AMI - Contact</title>
      </Helmet>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Contact</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="dedault">Contact</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className="content-wrap">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h4 className="ion-margin-bottom">Call the hotline.</h4>
                  <IonButton
                    fill="solid"
                    size="dedault"
                    className="button-text-white"
                    href="tel:+1.941.306.4660"
                    target="_blank"
                  >
                    (941) 306-4660
                  </IonButton>
                </IonCol>
                <IonCol>
                  <h4 className="ion-margin-bottom">Send an email.</h4>
                  <IonButton
                    fill="solid"
                    size="dedault"
                    className="button-text-white"
                    href="mailto:info@amiprojects.io"
                    target="_blank"
                  >
                    info@AMIprojects.io
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h4 className="ion-margin-bottom ion-margin-top">
                    Fill out a contact form.
                  </h4>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">First Name</IonLabel>
                    <IonInput
                      value={FirstName}
                      onIonChange={(e) => setFirstName(e.detail.value)}
                    ></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Last Name</IonLabel>
                    <IonInput
                      value={LastName}
                      onIonChange={(e) => setLastName(e.detail.value)}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Phone Number</IonLabel>
                    <IonInput
                      value={PhoneNumber}
                      onIonChange={(e) => setPhoneNumber(e.detail.value)}
                    ></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Email Address</IonLabel>
                    <IonInput
                      value={EmailAddress}
                      onIonChange={(e) => setEmailAddress(e.detail.value)}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Message</IonLabel>
                    <IonTextarea
                      value={Message}
                      onIonChange={(e) => setMessage(e.detail.value)}
                    ></IonTextarea>
                  </IonItem>
                  <IonButton
                    fill="solid"
                    size="dedault"
                    shape=""
                    className="ion-margin-top ion-margin-bottom button-text-white"
                  >
                    Send Message
                  </IonButton>
                  <br />
                  <IonText color="medium">
                    <p>
                      Under Florida law, e-mail addresses are public records. If
                      you do not want your e-mail address released in response
                      to a public-records request, do not send electronic mail
                      to this entity. Instead, contact this office by phone or
                      in writing.
                    </p>
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonPage>
    </div>
  );
};

export default Contact;
